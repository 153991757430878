import React from 'react';
import Text from 'components/ui/Text';
import styled from 'styled-components';
import { PAGE_INCLUSIONE_LAB_IMPACT } from 'types/url';
import { NavLink } from 'react-router-dom';
import TextBox from './TextBox';

const WrapperText = styled.div`
  line-height: 175%;
  margin-top: 1.8em;
`;

const Raggiungimi = () => (
  <TextBox title="RAGGIUNGIMI">
    <WrapperText>
      Il servizio del comune di Milano che informa, orienta e supporta persone e famiglie nel
      percorso del ricongiungimento familiare.
      <br />
      Il Servizio è finanziato dal Ministero del Lavoro e delle Politiche Sociali con il Fondo Nazionale per le Politiche Migratorie
    </WrapperText>
  </TextBox>
);

Raggiungimi.displayName = 'RaggiungimiPage';

export default Raggiungimi;
