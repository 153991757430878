import { getContentAllSections } from 'components/pages/Home/GraphQLHomepage';
import { useGraphQLRequest } from 'hooks/graphQLRequest/useGraphQLRequest';
import React, { useEffect, useMemo } from 'react';
import { PAGE_HOME_URL } from 'types/url';
import { normalizePath } from 'utils/normalize-path';

const withSectionVerifyPath = (Componente, redirectTo = PAGE_HOME_URL) =>
    props => {
      const [allSections] = useGraphQLRequest(undefined, getContentAllSections, {}, true);

      const { history, location } = props;

      const isAuthorized = useMemo(() => {
        if (allSections.data && allSections.data.list) {
          const isEnabled = allSections.data.list.some(
            (el) => normalizePath(el.link) === normalizePath(location.pathname)
          );

          return isEnabled;
        }

        return true;
      }, [location, allSections.data]);

      useEffect(() => {
        if (!isAuthorized) {
          history.push(redirectTo);
        }
      }, [redirectTo, history, isAuthorized]);

      if (isAuthorized) {
        return <Componente {...props} />;
      }
      return null;
    };

export default withSectionVerifyPath;
