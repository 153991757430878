import React from 'react';
import EducazioneFinanziariaNavigation from 'components/navigation/EducazioneFinanziaria';
import withSectionVerifyPath from 'hoc/withSectionVerifyPath';

const HomePageEducazioneFinanziaria = withSectionVerifyPath(() => (
  <>
    <EducazioneFinanziariaNavigation />
  </>
  ));

HomePageEducazioneFinanziaria.displayName = 'HomePageEducazioneFinanziaria';


export default HomePageEducazioneFinanziaria;
